// React
import React from 'react';

// Emotion / styling
import styled from '@emotion/styled';
import s from 'src/styles';

// Packages

// Utilities

// Components
import Button from 'components/button';

const DetailComponent = ({ name, detail }) => (
    <Detail>
        <span>{name}</span>
        <b>{detail}</b>
    </Detail>
);

const StepComponent = ({ index, step, label }) => (
    <Step>
        <span>
            {label} {index}
        </span>
        <b>{step}</b>
    </Step>
);

const GuideDetailsComponent = ({ guide, pageContext, theme }) => {
    const { labels } = pageContext;
    const { information, steps, pdf } = guide;
    return (
        <>
            <GuideDetails>
                <Details>
                    <h3>{labels.PAGES.GUIDES.INFO}</h3>
                    {information.map(item => (
                        <DetailComponent
                            key={item.name}
                            {...{ name: item.name, detail: item.description }}
                        />
                    ))}
                    {pdf && (
                        <a
                            href={pdf.url}
                            target="_blank"
                            rel="noreferrer noopener">
                            <Button
                                {...{
                                    theme,
                                    type: 'secondary',
                                    label: labels.PAGES.GUIDES.PDF,
                                }}
                            />
                        </a>
                    )}
                </Details>
                <Steps>
                    {steps.map((item, index) => (
                        <StepComponent
                            key={item.step}
                            {...{
                                index: index + 1,
                                step: item.step,
                                label: labels.PAGES.GUIDES.STEP,
                            }}
                        />
                    ))}
                </Steps>
            </GuideDetails>
        </>
    );
};

const GuideDetails = styled.div`
    color: ${s.color('black').default()};

    ${s.responsive.property('margin-top', {
        0: '3em',
        20: '5em',
    })};

    ${s.responsive.property('margin-bottom', {
        0: '0em',
        20: '5em',
    })};

    display: flex;
    position: relative;
    z-index: ${s.layout.zIndex.content};

    ${s.responsive.property('flex-direction', {
        0: 'column',
        30: 'row',
    })};
`;

const Details = styled.div`
    ${s.grid.columns('width', {
        0: 4,
        20: 6,
        30: 5,
    })};

    ${s.responsive.property('margin-bottom', {
        0: '3em',
        30: '0em',
    })};

    h3 {
        ${s.fonts.style('h13')};
        margin-bottom: 1.5em;
        padding-bottom: 0.2em;
        border-bottom: 1px solid currentColor;
    }

    button {
        margin-top: 3em;
    }
`;

const Detail = styled.p`
    ${s.fonts.style('h15')};

    display: flex;

    span {
        opacity: 0.5;

        ${s.fonts.style('h17')};

        ${s.grid.columns('width', {
            0: 1.5,
            20: [2, 0],
            30: [2, 0],
        })};
    }

    b {
        flex-grow: 0;
        font-weight: 400;

        ${s.grid.columns('width', {
            0: [2.5, 0],
            20: 2,
            30: 3,
        })};
    }
`;

const Steps = styled.div`
    ${s.grid.columns('width', {
        0: 4,
        20: 6,
    })};

    ${s.grid.columns('margin-left', {
        30: [1, 2],
    })};
    ${s.responsive.property('margin-bottom', {
        0: '3em',
        30: '0em',
    })};
`;

const Step = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 2.5em;

    span {
        ${s.fonts.style('h13')};
        margin-bottom: 0.5em;
    }

    b {
        ${s.fonts.style('h8')};
    }
`;

export default GuideDetailsComponent;
