// React
import React from 'react';

// Emotion / styling
import styled from '@emotion/styled';
import s from 'src/styles';

// Packages
import YouTube from 'react-youtube';
import { useGlobal } from 'reactn';

// Utilities
import { TransitionLink } from 'transition';
import { localizeLink } from 'utilities';

// Components
import Arrow from 'components/arrow';
import Image from 'components/image';

const GuideHeroComponent = ({
    guide,
    guides,
    pageContext,
    theme,
    location,
}) => {
    const { labels, locale } = pageContext;
    const { name, video, thumbnail } = guide;

    const [prevLocation] = useGlobal('prevLocation');

    return (
        <>
            <GuideHero>
                <ArrowWrapper>
                    <TransitionLink
                        from={location}
                        to={
                            prevLocation
                                ? prevLocation.pathname
                                : localizeLink(`/${guides.slug}/`, locale)
                        }
                        title={labels.PAGES.PRODUCT.BACK}
                        transition={[
                            'default',
                            {
                                theme,
                            },
                        ]}>
                        <Arrow {...{ theme, hover: true, direction: 'left' }} />
                    </TransitionLink>
                </ArrowWrapper>
                <Heading>{name}</Heading>
                {video ? (
                    <VideoWrapper
                        {...{ width: video.width, height: video.height }}>
                        <YouTube
                            {...{
                                videoId: video.providerUid,
                                opts: {
                                    width: video.width,
                                    height: video.height,
                                    playerVars: {
                                        modestbranding: 1,
                                        loop: 1,
                                        showinfo: 0,
                                    },
                                },
                            }}
                        />
                    </VideoWrapper>
                ) : (
                    <ImageWrapper>
                        <Image
                            {...{
                                source: thumbnail.fluid,
                                alt: name,
                            }}
                        />
                    </ImageWrapper>
                )}
            </GuideHero>
        </>
    );
};

const GuideHero = styled.div`
    ${s.responsive.property('padding-top', {
        0: 170,
        30: 230,
    })};

    position: relative;
    display: flex;
    flex-direction: column;
    color: ${s.color('black').default()};
`;

const ArrowWrapper = styled.div`
    position: relative;
    width: 50px;
    margin-top: -80px;
    margin-bottom: 20px;
    cursor: pointer;
`;

const Heading = styled.div`
    margin-top: 0.2em;
    margin-bottom: 0.5em;
    ${s.fonts.style('h2')};
    ${s.grid.columns('width', {
        0: 4,
        20: 5,
        30: 9,
    })};
`;

const VideoWrapper = styled.div`
    > div {
        position: relative;
        padding-bottom: ${props => `${(props.height / props.width) * 100}%`};
        width: 100%;
    }

    iframe {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;

const ImageWrapper = styled.div``;

export default GuideHeroComponent;
