// React
import React, { useEffect } from 'react';

// Gatsby
import { graphql } from 'gatsby';

// Emotion / styling
import styled from '@emotion/styled';
import s from 'src/styles';

// Utilities
import { useTheme } from 'hooks';

// Components
import SEO from 'components/seo';
import GuideHero from 'components/_guides/guideHero';
import GuideDetails from 'components/_guides/guideDetails';
import RelatedProducts from 'components/_shop/relatedProducts';

const GuidePageComponent = props => {
    const {
        location,
        pageContext,
        pageContext: { locale },
        data: {
            datoCmsBrewGuide,
            datoCmsBrewGuide: { seoMetaTags, relatedProducts },
            datoCmsPageGuide,
        },
    } = props;

    // Set theme
    const theme = useTheme(s.color('white'));

    return (
        <>
            <s.layout.PageContainer>
                <SEO {...{ seoMetaTags, locale }} />
                <ContentWrapper>
                    <GuideHero
                        {...{
                            theme,
                            pageContext,
                            location,
                            guide: datoCmsBrewGuide,
                            guides: datoCmsPageGuide,
                        }}
                    />
                    <GuideDetails
                        {...{
                            theme,
                            pageContext,
                            guide: datoCmsBrewGuide,
                        }}
                    />
                    {relatedProducts.length > 0 && (
                        <RelatedProducts
                            {...{
                                pageContext,
                                location,
                                products: relatedProducts,
                            }}
                        />
                    )}
                </ContentWrapper>
            </s.layout.PageContainer>
        </>
    );
};

const ContentWrapper = styled.div``;

export default GuidePageComponent;

export const GuidePageQuery = graphql`
    query GuidePageQuery($itemId: String!) {
        datoCmsBrewGuide(id: { eq: $itemId }) {
            id
            name
            pdf {
                url
            }
            thumbnail {
                fluid(maxWidth: 1600) {
                    ...GatsbyDatoCmsFluid_noBase64
                }
            }
            video {
                width
                height
                providerUid
            }
            information {
                name
                description
            }
            steps {
                step
            }
            seoMetaTags {
                ...SEO
            }
            relatedProducts {
                ... on DatoCmsFilterCoffee {
                    ...ProductFilterCoffeeFragment
                }
                ... on DatoCmsEspresso {
                    ...ProductEspressoFragment
                }
                ... on DatoCmsEquipment {
                    ...ProductEquipmentFragment
                }
                ... on DatoCmsOtherProduct {
                    ...ProductOtherProductFragment
                }
            }
        }
        datoCmsPageGuide {
            slug
        }
    }
`;
